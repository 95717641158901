import Vue from "vue";
import VueRouter from "vue-router";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "investment-management",
    meta: {
      title: "investment management",
    },
    component: () => import("@/views/InvestmentManagemen/InvestmentManagemen.vue"),
  },
  {
    path: "/investment-management",
    name: "investment-management",
    meta: {
      title: "investment management",
    },
    component: () => import("@/views/InvestmentManagemen/InvestmentManagemen.vue"),
  },
  {
    path: "/investment-management/investment-portfolios",
    name: "investment-management",
    meta: {
      title: "investment portfolios",
    },
    component: () => import("@/views/InvestmentManagemen/InvestmentPortfolio.vue"),
  },
  {
    path: "/investment-management/family-advisory",
    name: "investment-management",
    meta: {
      title: "family-advisory",
    },
    component: () => import("@/views/InvestmentManagemen/FamilyAdvisory.vue"),
  },
  {
    path: "/real-assets",
    name: "real-assets",
    meta: {
      title: "real-assets",
    },
    component: () => import("@/views/RealAssets/RealAssets.vue"),
  },
  {
    path: "/real-assets/digital-infrastructure",
    name: "real-assets",
    meta: {
      title: "digital-infrastructure",
    },
    component: () => import("@/views/RealAssets/DigitalInfrastructure.vue"),
  },
  {
    path: "/real-assets/accommodations",
    name: "real-assets",
    meta: {
      title: "accommodations",
    },
    component: () => import("@/views/RealAssets/accommodations.vue"),
  },
  {
    path: "/growth",
    name: "growth",
    meta: {
      title: "growth",
    },
    component: () => import("@/views/growth/growth.vue"),
  },
  {
    path: "/growth/companies",
    name: "growth",
    meta: {
      title: "companies",
    },
    component: () => import("@/views/growth/companies.vue"),
  },
  {
    path: "/growth/insights",
    name: "growth",
    meta: {
      title: "insights",
    },
    component: () => import("@/views/growth/insights.vue"),
  },
  {
    path: "/growth/updates",
    name: "growth",
    meta: {
      title: "updates",
    },
    component: () => import("@/views/growth/updates.vue"),
  },
  {
    path: "/growth/visionaries",
    name: "growth",
    meta: {
      title: "visionaries",
    },
    component: () => import("@/views/growth/visionaries.vue"),
  },
  {
    path: "/growth/2022-year-in-review",
    name: "growth",
    meta: {
      title: "2022-year-in-review",
    },
    component: () => import("@/views/growth/yearInReview.vue"),
  },
  {
    path: "/impact",
    name: "impact",
    meta: {
      title: "impact",
    },
    component: () => import("@/views/impact/impact.vue"),
  },
  {
    path: "/impact/co-labs",
    name: "impact",
    meta: {
      title: "co-labs",
    },
    component: () => import("@/views/impact/coLabs.vue"),
  },
  {
    path: "/impact/awards",
    name: "impact",
    meta: {
      title: "awards",
    },
    component: () => import("@/views/impact/awards.vue"),
  },
  {
    path: "/impact/insights",
    name: "impact",
    meta: {
      title: "insights",
    },
    component: () => import("@/views/impact/insights.vue"),
  },
  {
    path: "/news",
    name: "news",
    meta: {
      title: "news",
    },
    component: () => import("@/views/news/news.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
