import Vue from 'vue'
import App from './App.vue';
import router from "./router";
import store from "./store";

import Element from "element-ui";

import locale from "element-ui/lib/locale/lang/zh-CN";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/element-variables.scss";
import "@/assets/css/global.less";
import "@/assets/css/cdf28f5f29742fa2.css";
import "@/assets/css/normalize.css";
import "@/assets/css/blueprint.css";
import "@/assets/css/blueprint-select.css";
import "@/assets/css/cropper.css";

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(Element, { locale });
Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  /*********动态修改keywords和description*************/
  if(Object.keys(to.meta).length>0 && to.matched.length>0){
    let this_meta=to.matched[to.matched.length-1].meta
    if(this_meta.title)document.title=this_meta.title
    let head = document.getElementsByTagName('head');
    let meta_keyword=document.createElement('meta');
    if(document.querySelector('meta[name="keywords"]')){
      document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
    }else{
      meta_keyword.setAttribute('name','keywords')
      meta_keyword.setAttribute('content',this_meta.keywords)
      head[0].appendChild(meta_keyword)
    }
    let meta_description=document.createElement('meta');
    if(document.querySelector('meta[name="description"]')){
      document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
    }else{
      meta_description.setAttribute('name','description')
      meta_description.setAttribute('content',this_meta.description)
      head[0].appendChild(meta_description)
    }
  }
  /**********************************************/
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
