const state = {
  policyCategory: [], //已选择政策类别
  industryCode: [], //已选择行业分类
};

const mutations = {
  setPolicy: (state, policy) => {
    state.policyCategory = policy.policyCategory;
    state.industryCode = policy.policy;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
